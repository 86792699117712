import React, { useEffect, useState } from 'react';
import { Card, Grid, Input, Dropdown } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { AddressSuggestions, PartySuggestions, FioSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';

import './styles.css';
import {getAddressFields, getCountries, isCountriesFetching} from './reducer';

import {
  ADDRESS_FIRSTNAME,
  ADDRESS_LASTNAME,
  ADDRESS_COMPANY,
  ADDRESS_ADDRESS,
  ADDRESS_ADDINFO,
  ADDRESS_COUNTRY,
  ADDRESS_POSTCODE,
  ADDRESS_STATE,
  ADDRESS_CITY,
  ADDRESS_PHONE,
  ADDRESS_EMAIL,
  ADDRESS_FULLADDRESS,
  ADDRESS_GEOLON,
  ADDRESS_GEOLAT,
  ADDRESS_COMPANY_INN,
  ADDRESS_COMPANY_KPP,
  ADDRESS_COMPANY_OGRN,
  ADDRESS_COMPANY_ADDR,
  ADDRESS_CITY_KLADR,
  ADDRESS_COMPANY_JOB_TITLE,
  ADDRESS_COMPANY_SCOPE,
  setAddressFields, fetchCounties, ADDRESS_PHONE_COUNTRY,
} from './actions';
import { DADATA_API_KEY } from '../../config/secrets';
import _ from "lodash";
import PhoneCountryMask from "./PhoneCountryMask";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

export default function AddressCard(props) {

  const {dispatch} = props;
  const fields = useSelector((state) => getAddressFields(state.address));
  const isFetching = useSelector((state) => isCountriesFetching(state.address));
  const countries = useSelector((state) => getCountries(state.address));

  const language = useSelector(getLanguage);
  const languageString = language[0]+language[1];
  console.log(languageString);
  const t = (key) => {
    const languageString = language[0]+language[1];
    const translation = translations[languageString];
    return translation ? translation[key] || key : key;
  };

  useEffect(() => {
    if (!countries || !countries.countries || !countries.countries[fields[ADDRESS_COUNTRY]]) {
      dispatch(fetchCounties());
    }
    ValidateFields();
  }, []);

  const [state, setState] = useState({
    errors: {},
  });

  let listCountries = [],
      listPhoneCountries = [];
  if (!isFetching && countries && countries.countries) {
    listCountries = Object.values(countries.countries).map((element, index) => (
        {
          key:  index,
          text: languageString === "ru" ? element.country_ru : element.country_en,
          flag: _.lowerCase(element.iso),
          value: element.iso,
        }
    ));
    listPhoneCountries = Object.values(countries.countries).map((element, index) => (
        {
          key:  index,
          text: languageString === "ru" ? element.country_ru : element.country_en,
          flag: _.lowerCase(element.iso),
          value: element.iso,
          className: "dropdown-item",
          phone_mask: element.phone_mask,
        }
    ));
  }

  let listStates = [];

  setupCountry();

  function setupCountry() {
    if (!isFetching && countries && countries.countries && fields.countryCode) {
      const country = countries.countries[fields.countryCode];

      if (country) {
        listStates = Object.values(country.states).map((element, index) => (
            {
              key: index,
              text: languageString === "ru" ? element.name_ru : element.name_en,
              value: String(element.code),
            }
        ));

        const searchState = listStates.filter((state) => state.value === fields[ADDRESS_STATE]);

        if (listStates.length && !searchState.length)
          fields[ADDRESS_STATE] = listStates[0].value;

      }
    }
  }
  function setNativeValue(element, value) {
    let lastValue = element.value;
    element.value = value;
    let event = new Event("input", { target: element, bubbles: true });
    // React 15
    event.simulated = true;
    // React 16
    let tracker = element._valueTracker;
    if (tracker) {
      tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
  }

  function setAddressField(e) {
    fields[ADDRESS_ADDRESS] = e.target.value;
    fields[ADDRESS_FULLADDRESS] = '';
    fields[ADDRESS_GEOLON] = '';
    fields[ADDRESS_GEOLAT] = '';
    ValidateFields();
  }

  function setDaDataAddressField(e) {
    fields[ADDRESS_ADDRESS] = e.value;
    fields[ADDRESS_FULLADDRESS] = e.unrestricted_value;
    fields[ADDRESS_COUNTRY] = e.data.country_iso_code;
    fields[ADDRESS_POSTCODE] = e.data.postal_code ?? '';
    fields[ADDRESS_STATE] = e.data.region_kladr_id ? e.data.region_kladr_id.slice(0, 2) : e.data.region_iso_code;
    fields[ADDRESS_CITY] = e.data.region_with_type + ', ' + (e.data.settlement_with_type ?? e.data.city_with_type);
    fields[ADDRESS_CITY_KLADR] = e.data.settlement_kladr_id ?? e.data.city_kladr_id;
    fields[ADDRESS_GEOLON] = e.data.geo_lon;
    fields[ADDRESS_GEOLAT] = e.data.geo_lat;

    setNativeValue(document.getElementById(ADDRESS_CITY), fields[ADDRESS_CITY]);

    ValidateFields();
  }

  function setCompanyField(e) {
    fields[ADDRESS_COMPANY] = e.target.value;
    fields[ADDRESS_COMPANY_INN] = '';
    fields[ADDRESS_COMPANY_KPP] = '';
    fields[ADDRESS_COMPANY_OGRN] = '';
    fields[ADDRESS_COMPANY_ADDR] = '';
    ValidateFields();
  }

  function setDaDataCompanyField(e) {
    fields[ADDRESS_COMPANY] = e.value;
    fields[ADDRESS_COMPANY_INN] = e.data.inn;
    fields[ADDRESS_COMPANY_KPP] = e.data.kpp;
    fields[ADDRESS_COMPANY_OGRN] = e.data.ogrn;
    //fields[ADDRESS_COMPANY_ADDR] = e.data.address;
    fields[ADDRESS_COMPANY_ADDR] = e.data;

    ValidateFields();
  }

  function setCityField(e) {
    fields[ADDRESS_GEOLON] = '';
    fields[ADDRESS_GEOLAT] = '';
    fields[ADDRESS_CITY] = e.target.value;

    ValidateFields();
  }

  function setDaDataCityField(e) {
    fields[ADDRESS_CITY] = e.value;
    fields[ADDRESS_POSTCODE] = e.data.postal_code ?? '';
    fields[ADDRESS_STATE] = e.data.region_kladr_id ? e.data.region_kladr_id.slice(0, 2) : e.data.region_iso_code;
    fields[ADDRESS_CITY_KLADR] = e.data.settlement_kladr_id ?? e.data.city_kladr_id;
    fields[ADDRESS_GEOLON] = e.data.geo_lon;
    fields[ADDRESS_GEOLAT] = e.data.geo_lat;

    ValidateFields();
  }

  function setField(e) {
    fields[e.target.name] = e.target.value;
    ValidateFields();
  }

  function setDirectField(name, data) {
    fields[name] = data;
    ValidateFields();
  }

  function handleChangeCountry(e, { value }) {
    fields[ADDRESS_COUNTRY] = value;
    setupCountry();
    ValidateFields();
  }

  function handleChangeState(e, { value }) {
    fields[ADDRESS_STATE] = value;
    ValidateFields();
  }

  function ValidateFields() {

    let errors = {};

    if (!fields[ADDRESS_FIRSTNAME])
      errors[ADDRESS_FIRSTNAME] = t('required');
    else if (!fields[ADDRESS_FIRSTNAME].match(/^[a-zA-Zа-яА-Я\-\s]+$/))
      errors[ADDRESS_FIRSTNAME] = t('lettersSpacesAndHyphens');


    if (!fields[ADDRESS_LASTNAME])
      errors[ADDRESS_LASTNAME] = t('required');
    else if (!fields[ADDRESS_LASTNAME].match(/^[a-zA-Zа-яА-Я\-\s]+$/))
      errors[ADDRESS_LASTNAME] = t('lettersSpacesAndHyphens');

    if (!fields[ADDRESS_PHONE])
      errors[ADDRESS_PHONE] = t('required');
    else
    if (fields[ADDRESS_PHONE].indexOf('_') >= 0)
      errors[ADDRESS_PHONE] = t('enterTheNumberInFull');

    if (fields[ADDRESS_EMAIL]) {
      const lastAtPos = fields[ADDRESS_EMAIL].lastIndexOf('@');
      const lastDotPos = fields[ADDRESS_EMAIL].lastIndexOf('.');
      if (
          !(
              lastAtPos < lastDotPos && lastAtPos > 0
              && fields[ADDRESS_EMAIL].indexOf('@@') === -1 && lastDotPos > 2
              && fields[ADDRESS_EMAIL].length - lastDotPos > 2
          )
      )
        errors[ADDRESS_EMAIL] = t('invalidEmailFormat');
    }

    if(!fields[ADDRESS_EMAIL]) {
      errors[ADDRESS_EMAIL] = t('required');
    }

    if (!fields[ADDRESS_COUNTRY])
      errors[ADDRESS_COUNTRY] = t('required');

    if (!fields[ADDRESS_STATE])
      errors[ADDRESS_STATE] = t('required');


    fields['hasErrors'] = ! _.isEmpty(errors);
    setState({ errors });
    dispatch(setAddressFields(fields));
  }

  return (
      <Card centered className="shipping-details-card">
        <Card.Header className="user-card-header" as="h3" color="red" textAlign="center">{t('personalData')}</Card.Header>
        <Card.Content className="user-card-content">
          <Grid columns="equal" className="shipping-details-grid">
            <Grid.Row className="shipping-details-row">
              <Grid.Column className="shipping-field-cell">
                <FioSuggestions
                    containerClassName="shipping-field shipping-first-name"
                    inputProps={{
                      placeholder: t('name'),
                      name: [ADDRESS_FIRSTNAME],
                      onChange: (event) => setField(event),
                    }}
                    hintText={t('selectOptionContinue')}
                    filterParts={['NAME']}
                    count={4}
                    token={DADATA_API_KEY}
                    defaultQuery={fields[ADDRESS_FIRSTNAME]}
                    onChange={(e) => setDirectField(ADDRESS_FIRSTNAME, e.value)}
                />
                <span className="shipping-field-error">{state.errors[ADDRESS_FIRSTNAME]}</span>
              </Grid.Column>
              <Grid.Column className="shipping-field-cell">
                <FioSuggestions
                    containerClassName="shipping-field shipping-last-name"
                    inputProps={{
                      placeholder: t('surname'),
                      name: [ADDRESS_LASTNAME],
                      onChange: (event) => setField(event),
                    }}
                    hintText={t('selectOptionContinue')}
                    filterParts={['SURNAME']}
                    count={4}
                    token={DADATA_API_KEY}
                    defaultQuery={fields[ADDRESS_LASTNAME]}
                    onChange={(e) => setDirectField(ADDRESS_LASTNAME, e.value)}
                />
                <span className="shipping-field-error">{state.errors[ADDRESS_LASTNAME]}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="shipping-details-row">
              <Grid.Column className="shipping-field-cell">
                <PartySuggestions
                    containerClassName="shipping-field shipping-company-name"
                    inputProps={{
                      placeholder: t('OrganizationName'),
                      name: [ADDRESS_COMPANY],
                      onChange: (event) => setCompanyField(event),
                    }}
                    hintText={t('SelectAnOptionOrContinue')}
                    filterStatus={['ACTIVE']}
                    count={4}
                    token={DADATA_API_KEY}
                    defaultQuery={fields[ADDRESS_COMPANY]}
                    onChange={setDaDataCompanyField}
                />
                <span className="shipping-field-error">{state.errors[ADDRESS_COMPANY]}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="shipping-details-row">
              <Grid.Column className="shipping-field-cell">
                <Input
                    className="shipping-field shipping-address-job-title"
                    fluid
                    placeholder = {t('WhoAreYou')}
                    name={ADDRESS_COMPANY_JOB_TITLE}
                    value={fields[ADDRESS_COMPANY_JOB_TITLE]}
                    onChange={setField}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="shipping-details-row">
              <Grid.Column className="shipping-field-cell shipping-address-list">
                <Dropdown
                    fluid
                    name={ADDRESS_COUNTRY}
                    options={listCountries}
                    loading = {isFetching}
                    value={fields[ADDRESS_COUNTRY]}
                    onChange={handleChangeCountry}
                    search
                    selection
                    scrolling
                    noResultsMessage={t('notFound')}
                />
                <span className="shipping-field-error">{state.errors[ADDRESS_COUNTRY]}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="shipping-details-row">
              <Grid.Column className="shipping-field-cell shipping-address-list">
                <Dropdown
                    fluid
                    name={ADDRESS_STATE}
                    options={listStates}
                    loading = {isFetching}
                    value={fields[ADDRESS_STATE]}
                    onChange={handleChangeState}
                    search
                    selection
                    scrolling
                    noResultsMessage={t('notFound')}
                />
                <span className="shipping-field-error">{state.errors[ADDRESS_STATE]}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="shipping-details-row">
              <Grid.Column className="shipping-field-cell">
                <PhoneCountryMask
                    extraClass="shipping-field shipping-phone"
                    countries={listPhoneCountries}
                    loading={isFetching}
                    country={fields[ADDRESS_PHONE_COUNTRY]}
                    phone={fields[ADDRESS_PHONE]}
                    handleChangeField={setDirectField}
                    nameCountry={ADDRESS_PHONE_COUNTRY}
                    namePhone={ADDRESS_PHONE}
                    fluid={true}
                />
                <span className="shipping-field-error">{state.errors[ADDRESS_PHONE]}</span>
              </Grid.Column>
              <Grid.Column className="shipping-field-cell">
                <Input
                    className="shipping-field shipping-email"
                    fluid
                    placeholder={t('Email')}
                    name={ADDRESS_EMAIL}
                    value={fields[ADDRESS_EMAIL]}
                    onChange={setField}
                />
                <span className="shipping-field-error">{state.errors[ADDRESS_EMAIL]}</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
  );
}
