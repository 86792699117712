import {createStore, applyMiddleware, combineReducers} from 'redux';
import { persistCombineReducers, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { createReduxHistoryContext } from "redux-first-history";
import { reducer as toastr } from 'react-redux-toastr';
import thunk from 'redux-thunk';
import {createHashHistory} from 'history';

import categories from './views/Categories/reducer';
import products from './views/Products/reducer';
import reviews from './components/Reviews/reducer';
import cart from './views/Cart/reducer';
import variations from './components/Variations/reducer';
import search from './views/Search/reducer';
import navtopbar from './components/NavTopBar/reducer';
import navbottombar from './components/NavBottomBar/reducer';
import address from './components/AddressCard/reducer';
import shipping from './components/ShippingCard/reducer';
import payment from './views/Checkout_2_StepPayment/reducer';
import discount from './components/CouponCard/reducer';
import userLogin from './components/UserLogin/reducer';
import userLocation from './components/GeoLocation/reducer';
import orders from './views/Orders/reducer';
import faq from './views/FAQ/reducer';
import forums from './views/BusinessProgram/reducer';
import formReducer from "./views/EditProfile/reducer";
import exponents from "./views/Exponents/reducer";
import meets from "./views/AsiaMeet/reducer";

import languageReducer from "./components/LanguageModal/reducer";

export const mergeObjectArrays = (fromArray, toArray) => {

    let newArray = structuredClone(toArray);
    _.forEach(fromArray, (element) => {
        const index = newArray.findIndex(x => x.id === element.id);
        if (index !== -1)
            newArray[index] = element;
        else
            newArray.push(element);
    });

    // console.log('mergeObjectArrays', toArray, fromArray, newArray);
    return newArray;
}

const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
        history: createHashHistory(),
        savePreviousLocations: 2,
});

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: [
        'navtopbar',
        'navbottombar',
        'toastr',
        'router',
  ],
   //debug: true,
};

const rootReducer = persistCombineReducers(rootPersistConfig, {
  categories: persistReducer(
    {
      key: 'categories',
      storage,
      blacklist: ['isFetching', 'hasMore'],
    },
    categories,
  ),
  products: persistReducer(
    {
      key: 'products',
      storage,
      blacklist: ['isFetching', 'hasMore'],
    },
    products,
  ),
  reviews: persistReducer(
    {
      key: 'reviews',
      storage,
      blacklist: ['isFetching'],
    },
    reviews,
  ),
  variations: persistReducer(
    {
      key: 'variations',
      storage,
      blacklist: ['isFetching'],
    },
    variations,
  ),
  cart: persistReducer(
    {
      key: 'cart',
      storage,
    },
    cart,
  ),
  navtopbar,
  navbottombar,
  search: persistReducer(
      {
          key: 'search',
          storage,
          blacklist: ['isFetching', 'hasMore'],
      },
      search,
  ),
  toastr,
  address: persistReducer(
    {
      key: 'address',
      storage,
    },
    address,
  ),
  shipping: persistReducer(
    {
          key: 'shipping',
          storage,
        blacklist: ['isFetching', 'hasMore'],
    },
    shipping,
  ),
  payment: persistReducer(
    {
      key: 'payment',
      storage,
    },
    payment,
  ),
  discount: persistReducer(
    {
      key: 'discount',
      storage,
    },
    discount,
  ),
  userLogin: persistReducer(
    {
      key: 'userLogin',
      storage,
    },
    userLogin,
  ),
  userLocation: persistReducer(
    {
      key: 'userLocation',
      storage,
    },
    userLocation,
  ),
  orders: persistReducer(
        {
            key: 'orders',
            storage,
            blacklist: ['isFetching', 'hasMore'],
        },
        orders,
    ),
    faq: persistReducer(
        {
            key: 'faq',
            storage,
            blacklist: ['isFetching', 'hasMore'],
        },
        faq,
    ),
    forums: persistReducer(
        {
            key: 'forums',
            storage,
            blacklist: ['isFetching', 'hasMore'],
        },
        forums,
    ),
    exponents: persistReducer(
        {
            key: 'exponents',
            storage,
            blacklist: ['isFetching', 'hasMore'],
        },
        exponents,
    ),
    formReducer: persistReducer(
        {
            key: 'formReducer',
            storage,
        },
        formReducer,
    ),
    language: persistReducer(
        {
            key: 'language',
            storage,
        },
        languageReducer,
    ),
    meets: persistReducer(
        {
            key: 'meets',
            storage,
            blacklist: ['isFetching', 'hasMore'],
        },
        meets,
    ),
    router: routerReducer,
});


export const store = createStore(
    rootReducer,
    undefined,
    applyMiddleware(thunk, routerMiddleware)
);

export const routerHistory = createReduxHistory(store);
export const persistor = persistStore(store);
