import { toastr } from 'react-redux-toastr';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from "../../config/config";
import {dropToken} from "../../components/UserLogin/actions";

export const REQUEST_REQ_EXP = 'REQUEST_PLANNED_REQ';
export const RECEIVE_REQ_EXP = 'RECEIVE_PLANNED_REQ';
export const CREATE_REQ_EXP = 'CREATE_REQ_EXP';
export const CARDS_PER_PAGE = 10;

export const requestReqExp = () => ({
    type: REQUEST_REQ_EXP,
});

export const receiveReqExp = (meets, page= 1) => ({
    type: RECEIVE_REQ_EXP,
    meets,
    page,
});


const process_api_error = (json, dispatch, lang) => {
    if (json.code === AUTH_INVALID_TOKEN) {
        dispatch(dropToken());
        toastr.error(AUTH_INVALID_MESSAGE);
    } else
    if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
        toastr.error(json.message);
    else
        toastr.error(lang==='ru' ? 'Ошибка сервера: ' + String(json.code) + '. ' + json.message : json.data.status === 401 ?  'Server error: ' + String(json.code) + '. ' + "Sorry you are not allowed to perform this action" : "");
};

export const fetchMeetings = (params={}, token, lang) => (dispatch) => {
    dispatch(requestReqExp(params.page ?? 1));
    const headers = token ? { Authorization: 'Bearer ' + token} : {};
    let url = config.API_MEETINGS_URL;
    if (params) {
        url += '?'
            + Object.keys(params)
                .map((k) => k + '=' + encodeURIComponent(params[k]))
                .join('&');
    }
    return fetch(url, {headers})
        .then((response) => response.json())
        .then((json) => {
            if (json.code === 200) {
                dispatch(receiveReqExp(json.data, params.page ?? 1));
            } else {
                dispatch(receiveReqExp([]));
                process_api_error(json, dispatch, lang);
            }
        })
        .catch((error) => {
           toastr.error(lang === 'ru' ? 'Ошибка запроса' : 'Request error', String(error));
           dispatch(receiveReqExp([]));
        });
}

export const updateMeetingStatus = (token, meeting_id, lang, meeting_status, silent = false) => (dispatch) => {
    dispatch(requestReqExp());

    const headers = token ? { Authorization: 'Bearer ' + token } : {};
    let url = config.API_UPDATE_MEETING_URL + '' + meeting_id;

    const formData = new FormData();
    formData.append('lang', lang);
    formData.append('meeting_status', meeting_status);

    return fetch(url, { method: 'post', headers, body: formData, })
        .then((response) => response.json())
        .then((json) => {
            if (json.code === 200) {
                dispatch(receiveReqExp(json.data, 2));
                if (!silent)
                    toastr.success(lang === 'ru'
                        ?'Состояние встречи изменено и отправлены уведомления другой стороне'
                        :'The meeting status has been changed and notifications have been sent to the other party'
                    );
            } else {
                dispatch(receiveReqExp([], 2));
                process_api_error(json, dispatch, lang);
            }
        })
        .catch( (ex) => {
            dispatch(receiveReqExp([], 2));
            toastr.error( (lang === 'ru' ? 'Ошибка запроса: ' : 'Server error: ') + String(ex))
        });
};

export const createMeeting = (token, lang, expo_event, respondent_exponent, respondent_user, placement, on_date, on_time, name, silent = false) => (dispatch) => {
    dispatch(requestReqExp());

    const headers = token ? { Authorization: 'Bearer ' + token } : {};
    let url = config.API_CREATE_MEETING_URL;

    const formData = new FormData();
    formData.append('lang', lang);
    formData.append('expo_event', expo_event);
    formData.append('respondent_exponent', respondent_exponent);
    formData.append('respondent_user', respondent_user);
    formData.append('placement', placement);
    formData.append('on_date', on_date);
    formData.append('on_time', on_time);
    formData.append('name', name);

    return fetch(url, { method: 'post', headers, body: formData, })
        .then((response) => response.json())
        .then((json) => {
            if (json.code === 200) {
                dispatch(receiveReqExp(json.data, 2));
                if (!silent)
                    toastr.success(lang === 'ru'
                        ?'Встреча создана и уведомления другой стороне отправлены. Пожалуйста, ожидайте подтверждения'
                        :'The meeting has been created and notifications have been sent to the other party. Please wait for confirmation.'
                    );
            } else {
                dispatch(receiveReqExp([], 2));
                process_api_error(json, dispatch, lang);
            }
        })
        .catch( (ex) => {
            dispatch(receiveReqExp([], 2));
            toastr.error( (lang === 'ru' ? 'Ошибка запроса: ' : 'Server error: ') + String(ex))
        });
};
