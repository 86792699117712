import React, {useEffect, useState} from 'react';

import { ReactComponent as GeoPoint } from '../../icons/geo-point.svg';
import { ReactComponent as Vector } from '../../icons/vector.svg';

import 'semantic-ui-css/semantic.min.css'
import {Dimmer, Flag, Loader} from "semantic-ui-react";
import {useSelector} from "react-redux";

import {useParams} from "react-router";

import { ReactComponent as DownloadIcon} from "../../icons/download-presentation-icon.svg";
import { ReactComponent as WatchIcon} from "../../icons/check-icon.svg";
import { ReactComponent as VectorIcon} from "../../icons/vector-icon-expo.svg";

import {getExponents, getExponentsFetching} from "../Exponents/reducer";
import {EXPONENTS_PAGE_COUNT, fetchExponents, setTitle, setURL} from "../Exponents/action";
import BookmarkComponent from "../../components/Bookmark";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from "../../icons/sad-icon.png";
import ModalPDF from "../../components/ModalPDF/ModalPDF";

export default function ExponentCardArchive(props) {

    const { dispatch, userData, token } = props;

    const exponents = useSelector((state) => getExponents(state.exponents));
    const isFetching = useSelector((state) => getExponentsFetching(state.exponents));

    const { exponentId } = useParams();
    let exponentToDisplay = exponents ? exponents.find((exponent) => exponent.id === exponentId) : null;


    const [open, setOpen] = useState(false)
    const [modal, setModal] = useState(false)
    const [namePDF, setNamePDF] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0);
        const params = {
            page: 1,
            per_page: EXPONENTS_PAGE_COUNT,
            order: "asc",
            orderby: "id",
            event: "ASIAEXPO 2023",
            id: exponentId,
            user_id: userData.id,
            lang: languageString,
        };
        dispatch(fetchExponents(token, params));
    }, [exponentId]);

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const ExponentCard = (props) => {
        const { exponent, navigate } = props;

        // const handlePresentationClick = () => {
        //     window.open(exponent.promo_docs[1].url, '_blank');
        // };

        const locationExponent = exponent.events && exponent.events.active ? exponent.events.active.map(elem => elem.stand_number).join(", ") : '';
        const segmentsExponent = exponent.segments ? exponent.segments.map(elem => elem.name).join(', ') : '';
        const segmentsExponentEn = exponent.segments ? exponent.segments.map(elem => elem.name_en).join(', ') : '';
        const presentationExponent = exponent.promo_docs ? exponent.promo_docs.map(elem => elem.url).join(", ") : '';

        return (
            <div className="body_expo_card">
                <div>
                    <img src={exponent.logo} className="image_expo_card" alt="Image_expo_card"/>
                    <div className="info_expo_card">
                        <div className="text_expo_card">
                            <GeoPoint className="geo-point_expo_card" alt="Geo-point_expo_card"/>
                            <div className="location_expo_card">{locationExponent}</div>
                            <Flag name={exponent.country.toLowerCase()} className="country_Russia_exp card-expo"/>
                        </div>
                        <div
                            className="name_expo_card">{languageString === "en" ? exponent.name_en : exponent.name}</div>
                        <div className="company_info_expo_card"
                             dangerouslySetInnerHTML={{__html: languageString === "en" ? exponent.content_en : exponent.content}}>
                        </div>
                        {/* <div className="red_expo_card">
                        Контакты
                    </div>
                    <div className='photo_expo_card'>
                        <User className="user_expo_card" alt="User_expo_card"/>
                        <Ellipse className="ellipse_expo_card" alt="Ellipse_expo_card"/>
                    </div>
                    <div className="about_expo_card">
                        <strong>
                            <div className='people_name_expo_card'>Кравченко Егор</div>
                        </strong>
                        <Phone className="phone_expo_card" alt="Phone-point_expo_card"/>
                        +7 918 413 31 20
                        <br/>
                        <Mail className="mail_expo_card" alt="Mail_expo_card"/>
                        <div className="email_expo_card">e.kravchenko@feedtech.su</div>
                    </div> */}
                        <div className='container-exponents buttons'>
                            <a href={exponent.url ? exponent.url : `/#/archive-events/congress-asiaexpo-2023/exponents/exponents-card/${exponent.id}`}
                               className="stries_expo_card_link">
                                <div className="stries_expo_card">
                                    {t("toWebSite")}
                                    <Vector className="vector_expo_card" alt="Vector_expo_card"/>
                                </div>
                            </a>
                        </div>



                        <div className="stries_expo_card" onClick={() => setOpen(!open)}>{t("lookPresentation")}
                            {!open ? <Vector className="vector_expo_card" alt="Vector_expo_card"/> :
                                <VectorIcon className="vector_expo_card" alt="Vector_expo_card"/>}

                    </div>
                    <div className='container-watch-presents'>
                        {open ? exponent.promo_docs.map(function (docs) {
                            return (
                                <div className="container-present">
                                    <span className='text-presentation'>{docs.title}</span>
                                    <div className="presentation-icons">
                                        <WatchIcon className="watch-icon" onClick={() => {
                                            dispatch(setTitle(docs.title))
                                            dispatch(setURL(docs.url))
                                            navigate('/exponent-presentation')
                                            // (<PdfPresentation pdf={url}/>)
                                        }}/>
                                        <DownloadIcon className="watch-icon" onClick={async () => {
                                            const response = await fetch(docs.url);
                                            const blob = await response.blob();

                                            const link = document.createElement('a');
                                            link.href = window.URL.createObjectURL(blob);
                                            link.download = docs.title;
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        }}/>
                                    </div>
                                </div>

                            )
                        }) : ''}

                        {open && exponent.promo_docs.length === 0 ? (
                            <div className="container-present-not">
                                <div className='view-presentations'>
                                    <img src={SadIcon} alt={""}/>
                                    <span className='text-bookmark'>{t("noPresentations")}</span>
                                </div>
                            </div>) : ''}
                    </div>

                    {/*<ModalPDF*/}
                    {/*    open={modal}*/}
                    {/*    headerText={namePDF}*/}
                    {/*    urlPDF={url}*/}
                    {/*    handleOpen={() => setModal(true)}*/}
                    {/*    handleClose={() => setModal(false)}*/}
                    {/*/>*/}
                </div>
                <div className="red_expo_card">
                    {t("segments")}
                </div>
                <div className="segments_expo_card">
                    {/*<div className="underline_expo_card">{languageString === "en" ? segmentsExponentEn : segmentsExponent}</div>*/}
                    {exponent.segments && exponent.segments.map((segment, index) => (
                        <>
                                    <span
                                        key={index}
                                        className='underline_expo_card_archive'
                                    >
                                         <span
                                             className='dot'>•</span> {languageString === "ru" ? segment.name : segment.name_en}
                                        </span>
                        </>
                    ))}
                </div>

                <div className="flag_expo_card">
                    <BookmarkComponent
                        bookmark_using_for="exponents"
                        id={exponent.id}
                        {...props}
                    />
                </div>
            </div>
    </div>

    )
        ;
    };


    return (
        <div>
            <Dimmer className="page-loader" active={isFetching} inverted>
                <Loader active>{t("loadInfo")} ...</Loader>
            </Dimmer>
            {exponentToDisplay ? (
                <ExponentCard
                    key={exponentToDisplay.id}
                    exponent={exponentToDisplay}
                    showLoader
                    {...props}
                />
            ) : (
                <div className='view-bookmarks'>
                    <img src={SadIcon} alt={""}/>
                    <span className='text-bookmark'>{t("notExponents")}</span>
                </div>
            )}
        </div>
    );
}
