import React from 'react';
import './styles.css';



export default function PdfFile(props) {
    return (
        <div className='pdf-view'>
            <iframe className='pdf-file' src="https://drive.google.com/file/d/1CEDG6AyaB3zPUEhqXQ8PB-5NpI4w6vd1/preview" ></iframe>
        </div>
    );
}
