import React, {useEffect} from 'react';
import { Form, Radio, Label, Input } from 'semantic-ui-react';
import {
  PAYMENT_OPTION_BANK,
  PAYMENT_OPTION_CASH,
  PAYMENT_OPTION_ONLINE,
  setPaymentMethod
} from './actions';
import {
  SHIPPING_METHOD_OWN,
  SHIPPING_TYPE_LOCAL_PICKUP
} from '../../components/ShippingCard/actions';
import {
  ADDRESS_EMAIL, setAddressFields
} from '../../components/AddressCard/actions';
import {useDispatch, useSelector} from "react-redux";
import "./styles.css"
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

export default function PaymentOptions(props) {

  const dispatch = useDispatch();
  const {paymentOption, shippingMethod, shippingType, emailField} = props;

  const [state, setState] = React.useState(
      {
        errors: {}
      } );

  useEffect(() => {
    if ( typeof paymentOption === 'undefined' ||
        paymentOption === '0' ||
        paymentOption === PAYMENT_OPTION_CASH &&
        !(shippingMethod === SHIPPING_METHOD_OWN.key && shippingType === SHIPPING_TYPE_LOCAL_PICKUP) ) {
      setMethod(PAYMENT_OPTION_ONLINE);
    }
    saveField(emailField);
    }, []);

  function handleChange(e, { value }){
    setMethod( value );
  }

  function setMethod(newMethod) {
    dispatch(setPaymentMethod( newMethod ));
  }
    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };
function setField(e) {
    saveField(e.target.value);
  }

  function saveField(fieldValue) {
      let errors = {};

      if (fieldValue === '') {
        errors[ADDRESS_EMAIL] = t('required');
      } else {
        const lastAtPos = emailField.lastIndexOf('@');
        const lastDotPos = emailField.lastIndexOf('.');
        if (
          !(
            lastAtPos < lastDotPos && lastAtPos > 0 &&
            fieldValue.indexOf('@@') === -1 && lastDotPos > 2 &&
            fieldValue.length - lastDotPos > 2
          )
        ) {
          errors[ADDRESS_EMAIL] = t('invalidEmailFormat');
        }
      }

      setState({ ...state, errors });

      if (emailField !== fieldValue) {
        const fields = {};
        fields[ADDRESS_EMAIL] = fieldValue;
        dispatch(setAddressFields(fields));
      }
  }

    return (
      <Form>
        <Form.Field>
          <Radio
            label={t('online')}
            name='radioGroup'
            value={PAYMENT_OPTION_ONLINE}
            checked={paymentOption === PAYMENT_OPTION_ONLINE}
            onChange={handleChange}
            color='blue'
            className={"payment-options-radio"}
          />
          <Label as='a' className="label-payment-option-inline" image>
            <img alt="" src='https://e-feed.ru/wp-content/plugins/tinkoff-woocommerce/tinkoff/tinkoff.png'/>
          </Label><br/>
          <span className="label-payment-option">
            {t('onTheBankPage')}
          </span>
        </Form.Field>
        <Form.Field>
          <Radio
            label={t('bankTransfer')}
            name='radioGroup'
            value={PAYMENT_OPTION_BANK}
            checked={paymentOption === PAYMENT_OPTION_BANK}
            onChange={handleChange}
            color='blue'
            className={"payment-options-radio"}
          /><br/>
          <span className="label-payment-option">
            {t('byInvoiceDetailsOrQR')}
          </span>
          { paymentOption === PAYMENT_OPTION_BANK ?
          <React.Fragment>
            <Input
              name={ADDRESS_EMAIL}
              icon='mail'
              iconPosition='left'
              placeholder='введите адрес электронной почты'
              fluid
              transparent
              className='payment-option-email'
              color='blue'
              value={emailField}
              onChange={setField}
            />
            <span className="shipping-field-error">{state.errors[ADDRESS_EMAIL]}</span>
          </React.Fragment> : '' }
        </Form.Field>
        { shippingMethod === SHIPPING_METHOD_OWN.key && shippingType === SHIPPING_TYPE_LOCAL_PICKUP ?
          <Form.Field>
            <Radio
              label='Оплата при получении'
              name='radioGroup'
              value={PAYMENT_OPTION_CASH}
              checked={paymentOption === PAYMENT_OPTION_CASH}
              onChange={handleChange}
              color='blue'
              className={"payment-options-radio"}
            />
            <Label as='a' className="label-payment-option">
              наличными в пункте выдачи при получении заказа
            </Label>
          </Form.Field>
         : ''}
      </Form>
    );
}
