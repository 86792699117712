import React, { useEffect } from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {
  Icon, Label, Menu,
} from 'semantic-ui-react';
import {closeMenu, openMenu} from './actions';
import { getCart } from '../../views/Cart/reducer';
import { setPopupOpen } from "../../views/EditProfile/actions";

import {SERVICE_TASK_LEVEL_PREPARING, SERVICE_TASK_LEVEL_WORKING} from "../../config/config";

import UserLogin from "../UserLogin";
import HomeIconRed from '../../icons/home-icon-red.png';
import HomeIcon from '../../icons/home-icon.png';

import CalendarIcon from '../../icons/calendar-icon.png';
import CalendarIconRed from '../../icons/calendar-icon-red.png';

import BookmarkIcon from '../../icons/bookmark-icon.png';
import BookmarkIconRed from '../../icons/bookmark-icon-red.png';

import AsiameetIcon from '../../icons/asiameet-icon.png';
import AsiameetIconRed from '../../icons/asiameet-icon-red.png';

import ProfileIcon from '../../icons/profile-icon.png';
import ProfileIconRed from '../../icons/profile-icon-red.png';
import {openAuth} from "../NavTopBar/actions";
import {isAuthVisible} from "../NavTopBar/reducer";
import {getForums} from "../../views/BusinessProgram/reducer";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

export default function NavBottomBar (props) {

  const {menuVisible, dispatch, navigate, token, location, isLoggedIn} = props;

  const authVisible = useSelector((state) => isAuthVisible(state.navtopbar));
  const isFormChanged = useSelector((state) => state.formReducer.isFormChanged);
  const forums = useSelector((state) => getForums(state.forums));

  function showSidebar(e) {
    e.stopPropagation();
    dispatch( menuVisible ? closeMenu() : openMenu());
  }

  const language = useSelector(getLanguage);
  const t = (key) => {
    const languageString = language[0]+language[1];
    const translation = translations[languageString];
    return translation ? translation[key] || key : key;
  };


  return (
    <>
      { location.pathname === '/auth' || location.pathname === '/registration' || location.pathname === '/confirmation-code'
        ? ""
        : <Menu
              fluid
              secondary
              id="nav-bottom-bar"
              className="nav-bottom-bar"
              fixed="bottom"
              icon="labeled"
              inverted
          >
              <Menu.Item className="shop-icon-item" onClick={() => {isFormChanged && location.pathname === '/profile/edit' ? dispatch(setPopupOpen("/")) : navigate("/")}}>
                  <img className="shop-icon" src={

                    location.pathname === '/' ||
                    location.pathname === '/description-congress' ||
                    location.pathname === '/archive-events' ||
                    location.pathname === '/archive-events/congress-asiaexpo-2023' ||
                    location.pathname === '/archive-events/congress-asiaexpo-2023/business-program-archive' ||
                    location.pathname === '/archive-events/congress-asiaexpo-2023/cultural-program' ||
                    location.pathname === '/archive-events/congress-asiaexpo-2023/exponents' ||
                    location.pathname === '/hackathon-asiaexpo' ||
                    location.pathname === '/business-mission-asiaexpo' ||
                    location.pathname === '/events-asiaexpo' ||
                    location.pathname === '/cultural-program' ||
                    location.pathname === '/ticket' ||
                    location.pathname === '/travel-plan' ||
                    location.pathname === '/exposition' ||
                    location.pathname === '/business-program' ||
                    location.pathname === '/exponents'
                        ? HomeIconRed : HomeIcon
                  } alt=""/>
                  <div className="shop-text-item">{t('main')}</div>
              </Menu.Item>
              <Menu.Item name="tasks" className="shop-icon-item" onClick={() => {isFormChanged && location.pathname === '/profile/edit' ? dispatch(setPopupOpen()) : navigate("/schedule")}}>
                  <img className="shop-icon" src={location.pathname === '/schedule' ? CalendarIconRed : CalendarIcon} alt=""/>
                  <div className="shop-text-item">{t('timetable')}</div>
              </Menu.Item>
              <Menu.Item className="shop-icon-item" onClick={() => {isFormChanged && location.pathname === '/profile/edit' ? dispatch(setPopupOpen("/bookmarks")) : navigate("/bookmarks")}}>
                  <img className="shop-icon" src={location.pathname === '/bookmarks' ? BookmarkIconRed : BookmarkIcon} alt=""/>
                  <div className="shop-text-item">{t('bookmarks')}</div>
              </Menu.Item>
              <Menu.Item className="shop-icon-item" onClick={() => {isFormChanged && location.pathname === '/profile/edit' ? dispatch(setPopupOpen("/asiameet")) : navigate("/asiameet")}}>
                  <img className="shop-icon" src={
                    location.pathname === '/asiameet' ||
                    location.pathname === '/asiameet/form-meeting'
                        ? AsiameetIconRed : AsiameetIcon} alt=""/>
                  <div className="shop-text-item">AsiaMeet</div>
              </Menu.Item>

              <Menu.Item className="shop-icon-item" onClick={
                isLoggedIn
                    ? (isFormChanged && location.pathname === '/profile/edit'
                        ? () => dispatch(setPopupOpen("/profile"))
                        : () => navigate("/profile"))
                    : () => {
                        if (!authVisible)
                          dispatch(openAuth())
                      }
              }>
                <UserLogin showModal={authVisible} {...props}/>
                  <img className="shop-icon" src={
                    location.pathname === '/profile' ||
                    location.pathname === '/profile/edit' ||
                    location.pathname === '/profile/scan' ||
                    location.pathname === '/faq' ||
                    location.pathname === '/support' ||
                    location.pathname === '/profile/company' ||
                    location.pathname === '/profile/exponents'
                        ? ProfileIconRed : ProfileIcon
                  } alt=""/>

                  <div className="shop-text-item">{isLoggedIn ? t('profile') : t('login')}</div>
              </Menu.Item>
          </Menu>
}


    </>
  );
}
