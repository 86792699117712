import React from 'react';

import {
    Button,
    Header, Label,
} from 'semantic-ui-react';
import ModalPrompt from "../ModalPrompt";
import {updateOrder} from "../../views/Orders/actions";
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

export default function OrderCardHeader(props) {

    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    const {order, actionButtons, mixpanel, token, dispatch, userData, userLocation, isLoggedIn} = props;
    const {status, id} = order;
    const [open, setOpen] = React.useState(false);
    const placedOrder = isLoggedIn ? useSelector( (state) => state.userLogin.userData.orders.active[0]) : '';

    let orderLabelText = t('unissued'), labelColor = "red", icon = "";
    switch (isLoggedIn ? placedOrder.order_status : status) {
        case 'processing':
            orderLabelText = t('inTheWork');
            labelColor = "green";
            icon = "trash";
            break;
        case 'completed':
            orderLabelText = t("completed");
            labelColor = "green";
            icon = "trash";
            break;
        case 'on-hold':
        case 'pending':
            orderLabelText = t("waitingForPayment");
            labelColor = "red";
            icon = "trash";
            break;
        case 'cancelled':
            orderLabelText = t('cancelled');
            labelColor = "gray";
            icon = "undo";
            break;
        default:
            break;
    }


    function showPrompt() {
        setOpen(true);
        if (mixpanel)
            mixpanel.track('OrderCard Status Prompt', { ...userLocation, ...userData, status });
    }

    function orderState() {
        const data = {status: status === 'cancelled'? 'undo' : 'cancelled'};
        dispatch(updateOrder(token, id, JSON.stringify(data), order.order_key));
        setOpen(false);

        if (mixpanel)
            mixpanel.track('OrderCard Status Change', { ...userLocation, ...userData, ...data });
    }

    return (
        <div>
            <Header className="order-card-header" as="h3" color="red" textAlign="center">
                {/*<div dangerouslySetInnerHTML={{ __html: '№' + String(id) + ' от ' + orderDate }} />*/}
                {/*{ actionButtons ? <Button className="order-mini-card-cancel-button" icon={icon} onClick={showPrompt}/> : '' }*/}
                <Label as="a" color={ labelColor } ribon="" >{orderLabelText}</Label>
            </Header>
            <ModalPrompt
                open={open}
                headerIcon="question"
                headerText="Подтверждение"
                messageText={
                    status === 'cancelled'
                        ? `Вы хотите восстановить заказ №${id}?`
                        : `Действительно отменить заказ №${id}?`
                }
                handleYes={orderState}
                handleNo={() => setOpen(false)}
                handleClose={() => setOpen(false)}
                handleOpen={() => setOpen(true)}
            />
        </div>
    );
}