import { combineReducers } from "redux";
import {
    CARDS_PER_PAGE,
    REQUEST_REQ_EXP,
    RECEIVE_REQ_EXP,
    CREATE_REQ_EXP,
} from "./actions";
import {mergeObjectArrays} from "../../configureStore";

const items = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_REQ_EXP:
            if(action.page === 1)
                return action.meets;
            else
                return mergeObjectArrays(action.meets, state);
        case CREATE_REQ_EXP:
            return [action.meet, ...state];
        default:
            return state;
    }
}

const hasMoreReq = (state= false, action) => {
    switch(action.type) {
        case REQUEST_REQ_EXP:
            return true;
        case RECEIVE_REQ_EXP:
            return action.meets.length >= CARDS_PER_PAGE;
        default:
            return state;
    }
}
const isFetchingReq = (state = false, action) => {
    switch (action.type) {
        case REQUEST_REQ_EXP:
            return true;
        case RECEIVE_REQ_EXP:
            return false;
        default:
            return state;
    }
}



export const getReqExp = (state) => state.items;
export const getFetchingReq = (state) => state.isFetchingReq;
export const getHasMoreReq = (state) => state.hasMoreReq;


export default combineReducers({
   items,
   hasMoreReq,
   isFetchingReq,
});
