import React, {useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import "./style.css";
import { getLanguage } from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from '../../icons/sad-icon.png';
import {checkoutOrderPlaced, fetchOrders, ORDERS_PAGE_COUNT} from '../Orders/actions';
import {getOrders, getPlacedOrder} from "../Orders/reducer";
import {Dimmer, Header, Loader} from "semantic-ui-react";
import {Link, useNavigate} from "react-router-dom";
import {addProduct, removeAllProductsFromCart} from "../Cart/actions";
import {useParams} from "react-router";
import CheckoutSteps, {CHECKOUT_STEP_CONFIRM} from "../../components/CheckoutSteps";
import OrderCard from "../../components/OrderCard";
import ModalPayment from "../../components/ModalPayment/ModalPayment";
import {PAYMENT_OPTION_ONLINE} from "../Checkout_2_StepPayment/actions";

const Ticket = (props) => {
    const { isLoggedIn, dispatch, userData, token} = props;
    const navigate = useNavigate();
    const orders = isLoggedIn ? useSelector( (state) => state.userLogin.userData?.orders?.active?.[0]) : useSelector( (state) => getOrders(state.orders));
    const products = useSelector((state) => state.products.items);

    const matchParams = useParams();
    const orderKey = matchParams.orderKey;

    const placedOrder = isLoggedIn ? useSelector( (state) => state.userLogin.userData?.orders?.active) : useSelector( (state) => state.orders.placedOrder);
    console.log(placedOrder)

    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBuyTicket = () => {
        dispatch(removeAllProductsFromCart());
        const product = products[0];
        dispatch(addProduct(
            product.id,
            product.name,
            product.price,
            product.images[0],
            product.virtual,
            null,
            null,
        ));
        if (isLoggedIn)
            navigate('/checkout-step-payment')
        else
            navigate('/checkout-step-shipping');
    }


    if (isLoggedIn ? !orders : !placedOrder.billing) {
        return (
            <div className="no-ticket-page">
                <img src={SadIcon} alt="" />
                <div className="no-ticket-message">
                    {!isLoggedIn ? (
                        <span>{t('youDontHaveTicket')}<br/>{t('costTicket')}<br/>{t('pleaseLogInAndPay')}</span>
                    ) : (
                        <span>{t('youDontHaveTicket')}<br/>{t('costTicket')}<br/>{t('pleasePay')}</span>
                    )}
                </div>
                <div className="button-container" style={{marginTop: '20px'}}>
                    <button className="buy-ticket-button" onClick={handleBuyTicket}>
                        <span>{t('buyTicket')}</span>
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <CheckoutSteps step={CHECKOUT_STEP_CONFIRM} />
            <Header textAlign="center" className='checkout-step-confirm-header'>{t('yourUniqueCode')} - {isLoggedIn ? placedOrder[0].order_id : placedOrder.id}</Header>
            <OrderCard
                order={isLoggedIn ? orders : placedOrder}
                {...props}
            />
            <span className="checkout-step-confirm-text-asiaexpo">{t('PresentYourCodeAtTheRegistration')}<br/>{t('ASIAEXPOToReceiveYourBadge')}</span>
        </div>
    );
};

export default Ticket;
