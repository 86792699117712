import { toastr } from 'react-redux-toastr';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from "../../config/config";
import {dropToken} from "../../components/UserLogin/actions";

export const REQUEST_EXPONENTS = 'REQUEST_EXPONENTS';
export const RECEIVE_EXPONENTS = 'RECEIVE_EXPONENTS';
export const REQUEST_SEGMENTS = 'REQUEST_SEGMENTS';
export const RECEIVE_SEGMENTS = 'RECEIVE_SEGMENTS';

export const SET_COUNTRY_FILTER = 'SET_COUNTRY_FILTER';
export const SET_SEGMENT_FILTER = 'SET_SEGMENT_FILTER';


export const CLEAR_COUNTRY_FILTER = 'CLEAR_COUNTRY_FILTER';
export const CLEAR_SEGMENT_FILTER = 'CLEAR_SEGMENT_FILTER';


export const SET_SEARCH = 'SET_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const SET_CURRENT_SEGMENT = 'SET_CURRENT_SEGMENT';
export const CLEAR_CURRENT_SEGMENT = 'CLEAR_CURRENT_SEGMENT';

export const SET_URL = 'SET_URL';
export const CLEAR_URL = 'CLEAR_URL';

export const SET_TITLE = 'SET_TITLE'

export const setURL = (url) => ({
    type: SET_URL,
    url,
});

export const setTitle = (title) => ({
    type: SET_TITLE,
    title,
});

export const setCurrentSegment = (segment) => ({
    type: SET_CURRENT_SEGMENT,
    segment,
});


export const setSearch = (search) => ({
    type: SET_SEARCH,
    search,
});

export const clearSearch = () => ({
    type: CLEAR_SEARCH,
});

export const setCountryFilter = (country) => ({
    type: SET_COUNTRY_FILTER,
    country,
});

export const setSegmentFilter = (segment) => ({
    type: SET_SEGMENT_FILTER,
    segment,
});

export const clearCountryFilter = () => ({
    type: CLEAR_COUNTRY_FILTER,
});

export const clearSegmentFilter = () => ({
    type: CLEAR_SEGMENT_FILTER,
});


export const requestExponents = () => ({
    type: REQUEST_EXPONENTS,
});

export const receiveExponents = (exponents, page = 1) => ({
    type: RECEIVE_EXPONENTS,
    exponents,
    page,
});

export const requestSegments = () => ({
    type: REQUEST_SEGMENTS,
});

export const receiveSegments = (segments) => ({
    type: RECEIVE_SEGMENTS,
    segments
});

export const EXPONENTS_PAGE_COUNT = 20;

const process_api_error = (json, dispatch) => {
    if (json.code === AUTH_INVALID_TOKEN) {
        dispatch(dropToken());
        toastr.error(AUTH_INVALID_MESSAGE);
    } else
    if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
        toastr.error(json.message);
    else
        toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchExponents = (token, params = {}) => (dispatch) => {

    dispatch(requestExponents( params.page ?? 1 ));

    let url = config.API_EXPONENTS_URL;
    if (params)
        url += '?'
            + Object.keys(params)
                .map((k) => k + '=' + encodeURIComponent(params[k]))
                .join('&');

    const headers = token ? { Authorization: 'Bearer ' + token } : {};

    return fetch(url, {headers})
        .then((response) => response.json())
        .then((json) => {
            if (json.code === 200) {
                dispatch(receiveExponents(json.data, params.id ? 2 : (params.page ?? 1)));
            } else {
                dispatch(receiveExponents([]));
                process_api_error(json, dispatch);
            }
        })
        .catch((error) => {
            toastr.error('Ошибка запроса', String(error));
            dispatch(receiveExponents([]));
        });
};

export const fetchSegments = (params = {}) => (dispatch) => {

    dispatch(requestSegments( params.page ?? 1 ));

    let url = config.API_SEGMENTS_URL;
    if (params)
        url += '?'
            + Object.keys(params)
                .map((k) => k + '=' + encodeURIComponent(params[k]))
                .join('&');

    return fetch(url)
        .then((response) => response.json())
        .then((json) => {
            if (json.code === 200) {
                dispatch(receiveSegments(json.data, params.page ?? 1));
            } else {
                dispatch(receiveSegments([]));
                process_api_error(json, dispatch);
            }
        })
        .catch((error) => {
            toastr.error('Ошибка запроса', String(error));
            dispatch(receiveSegments([]));
        });
};
