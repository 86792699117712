import React, {useEffect} from 'react';

import {Link} from "react-router-dom";

import "./style.css"

import  ExpositionIcon  from '../../icons/exposition-icon.png';
import  BusIcon  from '../../icons/business-program-icon.png';
import  CulIcon  from '../../icons/cultural-program-icon.png';
import  ExhibitionIcon  from '../../icons/exhibition-icon.png';
import  PhotoIcon  from '../../icons/photobank-icon.png';
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

export default function CongressAE2023 (props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    return (
        <>

            <div className="main-block1">
                <div className="main-container1">
                    <div className="container-cards1">
                        <Link className="icontab1" to='/archive-events/congress-asiaexpo-2023/business-program-archive'>
                            <img src={BusIcon}/>
                        </Link>
                        <Link className="label-for-icon1" to='/archive-events/congress-asiaexpo-2023/business-program-archive'>
                            <p className="text-for-icon1">{t("busProgram")}</p>
                        </Link>
                    </div>

                    <div className="container-cards1">
                        <Link className="icontab1" to='/archive-events/congress-asiaexpo-2023/cultural-program'>
                            <img src={CulIcon}/>
                        </Link>
                        <Link className="label-for-icon1" to='/archive-events/congress-asiaexpo-2023/cultural-program'>
                            <p className="text-for-icon1">{t("culProgram")}</p>
                        </Link>
                    </div>

                    <div className="container-cards1">
                        <Link className="icontab1" to='/archive-events/congress-asiaexpo-2023/exponents'>
                            <img src={ExhibitionIcon}/>
                        </Link>
                        <Link className="label-for-icon1" to='/archive-events/congress-asiaexpo-2023/exponents'>
                            <p className="text-for-icon1">{t("exhibitors")}</p>
                        </Link>
                    </div>

                    {/*<div className="container-cards1">*/}
                    {/*    <Link className="icontab1">*/}
                    {/*        <img src={PhotoIcon}/>*/}
                    {/*    </Link>*/}
                    {/*    <Link className="label-for-icon1">*/}
                    {/*        <p className="text-for-icon1">Фотобанк</p>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
}