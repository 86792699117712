import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import './style.css';
import {Button, Dimmer, Loader} from 'semantic-ui-react';
import {actions, toastr} from "react-redux-toastr";
import { v4 as uuidv4 } from 'uuid';

import CalendarIcon from "../../icons/calendar-icon.png";
import SadIcon from "../../icons/sad-icon.png";

import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {getReqExp, getFetchingReq, getHasMoreReq} from "./reducer";
import {fetchMeetings, updateMeetingStatus, CARDS_PER_PAGE} from "./actions";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import InfiniteView from "../../components/InfiniteView";


export default function AsiaMeet(props) {
    const { token, navigate, dispatch, routeAction, isPageReloaded } = props;
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };
    if (!token) {
        navigate('/');
        toastr.error(t('notAuthorized'));
    }
    const [isPlanedMeeting, setIsPlanedMeeting] = useState(true);

    const meets = useSelector((state) => getReqExp(state.meets));
    const isFetching = useSelector((state) => getFetchingReq(state.meets));
    const [isLoading, setIsLoading] = useState({});
    const hasMore = useSelector((state) => getHasMoreReq(state.meets));
    useEffect(() => {
        if ( routeAction === 'PUSH' || isPageReloaded ) {
            setWindowScrollPosition();
            setIsLoading({});
        }
        if (!token) {
            navigate('/');
            toastr.error(t('notAuthorized'));
        }
    }, [])

    useEffect(() => {
        if(isPlanedMeeting) {
            readPlanned(1);
        } else {
            readRequests(1);
        }
    }, [isPlanedMeeting]);

    const readRequests = (page) => {
        const params = {
            page,
            per_page: CARDS_PER_PAGE,
            order: 'desc',
            orderby: 'on_date',
            event: 'ASIAEXPO 2024',
            lang: languageString,
            statuses: ['directed'],
        }
        dispatch(fetchMeetings(params, token, languageString));
    }
    const readPlanned = (page) => {
        const params = {
            page,
            per_page: CARDS_PER_PAGE,
            order: 'desc',
            orderby: 'on_date',
            event: 'ASIAEXPO 2024',
            lang: languageString,
            statuses: ['accepted', 'canceled', 'rejected', 'completed', 'deleted'],
        }
        dispatch(fetchMeetings(params, token, languageString));
    }

    const loadMoreReq = () => readRequests(Math.round(meets.length / CARDS_PER_PAGE) + 1);
    const loadMorePlanned = () => readPlanned(Math.round(meets.length / CARDS_PER_PAGE) + 1);

    const MeetsList = (props) => {
        const { meets } = props;
        return (
            <div className="asiameet-content">
                {meets.map((meet) => {
                    return isPlanedMeeting ? plannedMeeting(meet) : requestMeeting(meet)
                })}
            </div>
        )
    }
    const requestMeeting = (request) => {
        const buttons = []
        const handleUpdateMeetingStatus = (id, status) => {
            setIsLoading({...isLoading, [request.id]: true});
            dispatch(updateMeetingStatus(token, id, languageString, status))
                .then(() => {
                    readRequests(1);
                    setIsLoading({...isLoading, [request.id]: false})
                })
                .catch(() => {
                    setIsLoading({...isLoading, [request.id]: false})
                })
        };
        Object.values(request.actions).forEach((action) => {
            if (action.en === 'Accept') {
                buttons.push(
                    <button
                        className="asiameet-card-btn card-btn-red"
                        onClick={() => handleUpdateMeetingStatus(request.id, 'accepted')}
                    >
                        {languageString === "ru" ? action.ru : action.en}
                    </button>
                );
            } else if (action.en === 'Reject') {
                buttons.push(
                    <button
                        className="asiameet-card-btn card-btn-gray"
                        onClick={() => {
                            handleUpdateMeetingStatus(request.id, 'rejected');
                        }}
                    >
                        {languageString === "ru" ? action.ru : action.en}
                    </button>
                );
            } else if (action.en === 'Cancel') {
                buttons.push(
                    <button
                        className="asiameet-card-btn card-btn-gray"
                        onClick={() => {
                            handleUpdateMeetingStatus(request.id, 'canceled');
                        }}
                    >
                        {languageString === "ru" ? action.ru : action.en}
                    </button>
                );
            }
        });

        return (
            <div className="asiameet-card" id={request.id}>
                <div className="asiameet-card-time">
                    <div className="asiameet-card-time-container">
                        <img src={CalendarIcon} className="asiameet-card-time-icon" />
                        <div className="asiameet-card-time-text">{request.on_date.split('-').reverse().join('.') + ' ' + request.on_time}</div>
                        <div className="asiameet-card-time-text">{languageString === 'ru' ? request.meeting_status_name : request.meeting_status_name_en}</div>
                    </div>
                </div>
                <div>
                    <div className="asiameet-card-container">
                        <div className="asiameet-card-text-container">
                            <div className='asiameet-card-text'>{request.name}</div>
                            <div className="asiameet-card-text"><span
                                style={{fontWeight: 'bold'}}>{t('placeholderPlaceText')}:</span> {request.placement}
                            </div>
                            <div className="asiameet-card-text">
                                <span style={{fontWeight: 'bold'}}>{t('company')}: </span>
                                {languageString === "ru" ?
                                    request.respondent_exponent_name
                                    :
                                    request.respondent_exponent_name_en}</div>
                            <div className="asiameet-card-text">
                                <span style={{fontWeight: 'bold'}}>{t('initiator')}: </span>
                                {languageString === "ru" ?
                                    request.initiator_user_name
                                    :
                                    request.initiator_user_name_en}
                            </div>
                            <div className="asiameet-card-text">
                                <span style={{fontWeight: 'bold'}}>{t('responder')}: </span>
                                {languageString === "ru" ?
                                    request.respondent_user_name
                                    :
                                    request.respondent_user_name_en}
                            </div>
                        </div>
                        <div className="asiameet-card-btn-container">
                            {buttons.map((button) => (
                                button
                            ))}
                        </div>
                    </div>
                </div>
                <Dimmer active={isLoading[request.id]} inverted
                        style={{borderRadius: '10px', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                    <Loader />
                </Dimmer>
            </div>
        )
    }

    const plannedMeeting = (meet) => {
        const buttons = [];
        const handleUpdateMeetingStatus = (id, status) => {
            setIsLoading({...isLoading, [meet.id]: true});
            dispatch(updateMeetingStatus(token, id, languageString, status))
                .then(() => {
                    readPlanned(1);
                    setIsLoading({...isLoading, [meet.id]: false})
                })
                .catch(() => {
                    setIsLoading({...isLoading, [meet.id]: false})
                })
        };
        Object.values(meet.actions).forEach((action) => {
            if (action.en === 'Complete') {
                buttons.push(
                    <button
                        className="asiameet-card-btn card-btn-red"
                        onClick={() => {
                            handleUpdateMeetingStatus(meet.id, 'completed');
                        }}
                    >
                        {languageString === "ru" ? action.ru : action.en}
                    </button>
                );
            }
            else if (action.en === 'Cancel') {
                buttons.push(
                    <button
                        className="asiameet-card-btn card-btn-gray"
                        onClick={() => {
                            handleUpdateMeetingStatus(meet.id, 'canceled');
                        }}
                    >
                        {languageString === "ru" ? action.ru : action.en}
                    </button>
                );
            }
        });


        return (
                <div className="asiameet-card-planned" id={meet.id}>
                    <div className="asiameet-card-time">
                        <div className="asiameet-card-time-container">
                            <img src={CalendarIcon} className="asiameet-card-time-icon"/>
                            <div
                                className="asiameet-card-time-text">{meet.on_date.split('-').reverse().join('.') + ' ' + meet.on_time}</div>
                            <div
                                className="asiameet-card-time-text">{languageString === 'ru' ? meet.meeting_status_name : meet.meeting_status_name_en}</div>
                        </div>
                    </div>
                    <div>
                        <div className="asiameet-card-container">
                        <div className="asiameet-card-text-container">
                                <div className='asiameet-card-text'>{meet.name}</div>
                                <div className="asiameet-card-text"><span
                                    style={{fontWeight: 'bold'}}>{t("placeholderPlaceText")}:</span> {meet.placement}</div>
                                <div className="asiameet-card-text">
                                    <span style={{fontWeight: 'bold'}}>{t('company')}: </span>
                                    {languageString === "ru" ?
                                        meet.respondent_exponent_name
                                        :
                                        meet.respondent_exponent_name_en}</div>
                                <div className="asiameet-card-text">
                                    <span style={{fontWeight: 'bold'}}>{t('initiator')}: </span>
                                    {languageString === "ru" ?
                                        meet.initiator_user_name
                                        :
                                        meet.initiator_user_name_en}
                                </div>
                                <div className="asiameet-card-text">
                                    <span style={{fontWeight: 'bold'}}>{t('responder')}: </span>
                                    {languageString === "ru" ?
                                        meet.respondent_user_name
                                        :
                                        meet.respondent_user_name_en}
                                </div>
                                <div className="asiameet-card-btn-container">
                                    {buttons.reverse().map((button) => (
                                        button
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dimmer active={isLoading[meet.id]} inverted style={{borderRadius: '10px',position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                        <Loader />
                    </Dimmer>
                </div>
        )
    }


    return (
        <div className="asiameet-view">
            <div className="asiameet-selector">
                <div
                    onClick={() => setIsPlanedMeeting(true)}
                    className={"asiameet-selector-item" + (isPlanedMeeting ? " selector-active-1" : "")}
                >
                    {t("planned")}
                </div>
                <div
                    onClick={() => setIsPlanedMeeting(false)}
                    className={"asiameet-selector-item" + (!isPlanedMeeting ? " selector-active-2" : "")}
                >
                    {t("requests")}
                </div>
            </div>
            <InfiniteView
                dataLength={meets.length}
                nextFetch={isPlanedMeeting ? loadMorePlanned : loadMoreReq}
                hasMore={hasMore}
                isLoading={isFetching}
                dataList={(
                    <MeetsList
                        meets={meets}
                        {...props}
                    />
                )}
                hideEndMessage={true}
                mustAuth={false}
                loaderText={'Загрузка...'}
                zeroText={(
                    <div className='view-bookmarks'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{isPlanedMeeting ? t("noPlanned") : t("noRequests")}</span>
                    </div>
                )}
            />


        </div>
    )
}
