import React, {useEffect} from 'react';
import './styles.css';
import {useSelector} from "react-redux";
import {getPDFExponent} from "../Exponents/reducer";
import {EXPONENTS_PAGE_COUNT, fetchExponents} from "../Exponents/action";



export default function PdfPresentation(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const pdf = useSelector((state) => getPDFExponent(state.exponents));
    return (
        <div className='pdf-view'>
            <iframe className='pdf-file' src={pdf.url}></iframe>
        </div>
    );
}
