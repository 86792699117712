import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    Card, Divider, Header, Icon, Loader, Button,
    Segment, Modal, Dimmer, Image, Grid,
} from 'semantic-ui-react';

import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import {Link} from "react-router-dom";

import './styles.css';
import {getAddressFields, getCountries, isCountriesFetching} from "../AddressCard/reducer";
import {
    ADDRESS_ADDINFO,
    ADDRESS_ADDRESS,
    ADDRESS_CITY,
    ADDRESS_COMPANY,
    ADDRESS_COMPANY_ADDR,
    ADDRESS_COMPANY_JOB_TITLE,
    ADDRESS_COMPANY_SCOPE,
    ADDRESS_COUNTRY,
    ADDRESS_EMAIL,
    ADDRESS_FIRSTNAME, ADDRESS_GEOLAT, ADDRESS_GEOLON,
    ADDRESS_LASTNAME,
    ADDRESS_PHONE,
    ADDRESS_POSTCODE,
    ADDRESS_STATE,
    fetchCounties,
    setAddressFields
} from "../AddressCard/actions";
import {getAuthToken, getUserData} from "../UserLogin/reducer";
import OrderCardHeader from "./OrderCardHeader";
import AddressCard from "../AddressCard";
import {receiveOrderData, updateOrder} from "../../views/Orders/actions";
import {useReactToPrint} from "react-to-print";
import ModalPayment from "../ModalPayment/ModalPayment";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

export default function MiniOrderCard(props) {

    const { order, circular, header, actionButtons, dispatch, token, userData, userLocation, mixpanel , isLoggedIn } = props;
    const { billing, id } =  order;

    const userDataBilling = isLoggedIn ? useSelector(state=>state.userLogin.userData.billing) : ''
    const userDataOrder = isLoggedIn ? useSelector(state=>state.userLogin.userData.orders.active[0]) : ''

    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));
    const fields = useSelector((state) => getAddressFields(state.address));

    const [openAddressModal, setOpenAddressModal] = useState(false);
    const [paymentOpen, setPaymentOpen] = React.useState(false);

    const country = typeof countries === 'object' && typeof countries.countries === 'object'
        ? countries.countries[isLoggedIn ? userDataBilling.country : billing.country] ?? {} : {};
    const countryStates = typeof country === 'object' && country.states
        ? country.states.filter( item => String(item.code) === (isLoggedIn ? userDataBilling.state : billing.state) ) : [];

    useEffect(() => {
        if (typeof country.iso === 'undefined' && !getLoadingCountries) {
            console.warn('Mini order card has queried countries ...');
            dispatch(fetchCounties());
        }
        dispatch(receiveOrderData([]));
    }, []);

    const orderCountry = country.iso ? country.emoji + ' ' + country.country_ru : billing.country;
    const orderState = countryStates.length ? countryStates[0].name : `регион ${billing.state}`;
    const orderAddress = isLoggedIn ? userDataBilling.city : billing.city === isLoggedIn ? userDataBilling.address_1 : billing.address_1 ? '' : isLoggedIn ? userDataBilling.address_1 : billing.address_1;

    const needsPayment = isLoggedIn ? userDataOrder.order_status === 'pending' || userDataOrder.order_status === 'on-hold' : order.status === 'pending' || order.status === 'on-hold';

    const OrderImage = () => (
        <Image src={userData.avatar ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}
               size="medium"
               circular={ order.file_image ? false : circular}
               className="order-image"
        />
    );

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    // function openModalAddress() {
    //
    //     const orderFields = {
    //         [ADDRESS_FIRSTNAME]: isLoggedIn ? userDataBilling.first_name : billing.first_name,
    //         [ADDRESS_LASTNAME]: isLoggedIn ? userDataBilling.last_name : billing. last_name,
    //         [ADDRESS_COMPANY]: isLoggedIn ? userDataBilling.company: billing.company ?? '',
    //         [ADDRESS_COMPANY_JOB_TITLE]: isLoggedIn ? userDataBilling.company_job_title : billing.company_job_title ?? '',
    //         [ADDRESS_COMPANY_SCOPE]: isLoggedIn ? userDataBilling.company_scope : billing.company_scope ?? '',
    //         [ADDRESS_ADDRESS]: isLoggedIn ? userDataBilling.address_1 : billing.address_1 ?? '',
    //         [ADDRESS_COUNTRY]: isLoggedIn ? userDataBilling.country : billing.country,
    //         [ADDRESS_STATE]: isLoggedIn ? userDataBilling.state : billing.state,
    //         [ADDRESS_CITY]: isLoggedIn ? userDataBilling.city : billing.city,
    //         [ADDRESS_POSTCODE]: isLoggedIn ? userDataBilling.postcode : billing.postcode,
    //         [ADDRESS_PHONE]: isLoggedIn ? userDataBilling.phone : billing.phone,
    //         [ADDRESS_EMAIL]: isLoggedIn ? userDataBilling.email : billing.email ?? '',
    //     };
    //
    //     dispatch(setAddressFields(orderFields));
    //     setOpenAddressModal(true);
    //
    //     if (mixpanel)
    //         mixpanel.track('OrderCard Edit Address Open', { ...userLocation, ...userData, id });
    // }
    function changeOrderAddress() {
        const savedFields = {
            user_location: {
                userData: {id: userData.id, login: userData.login},
                userLocation,
            },
            company_meta: fields[ADDRESS_COMPANY_ADDR] ?? '',
            lon: fields[ADDRESS_GEOLON] ?? '',
            lat: fields[ADDRESS_GEOLAT] ?? '',
            first_name: fields[ADDRESS_FIRSTNAME],
            last_name: fields[ADDRESS_LASTNAME],
            company: fields[ADDRESS_COMPANY],
            company_job_title: fields[ADDRESS_COMPANY_JOB_TITLE],
            company_scope: fields[ADDRESS_COMPANY_SCOPE],
            address_1: fields[ADDRESS_ADDRESS],
            address_2: fields[ADDRESS_ADDINFO],
            country: fields[ADDRESS_COUNTRY],
            state: fields[ADDRESS_STATE],
            city: fields[ADDRESS_CITY],
            postcode: fields[ADDRESS_POSTCODE],
            phone: fields[ADDRESS_PHONE],
            email: fields[ADDRESS_EMAIL],
        };

        const query = JSON.stringify({billing: savedFields});
        dispatch(updateOrder(token, id, query, order.order_key));

        setOpenAddressModal(false);

        if (mixpanel)
            mixpanel.track('OrderCard Edit Address Save', { ...userLocation, ...userData, id });
    }
    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    const modalAddress = () => (
        <Modal
            className="modal-order-edit-address"
            closeIcon
            dimmer
            open={openAddressModal}
            onClose={()=>setOpenAddressModal(false)}
            onOpen={()=>setOpenAddressModal(true)}
            centered
            size="fullscreen"
            closeOnDimmerClick={true}
        >
            <Header icon="address card" content={`Изменение заказа №${id}`} />
            <Modal.Content>
                <AddressCard {...props}/>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={()=>setOpenAddressModal(false)}>
                    <Icon name='remove' /> {t('cancel')}
                </Button>
                <Button color='green' onClick={changeOrderAddress} disabled={fields.hasErrors}>
                    <Icon name='checkmark' /> {t('save')}
                </Button>
            </Modal.Actions>
        </Modal>
    );

    return (
        <div>
            <Card centered color="blue" raised className="card-order">
                { header ?
                    <div>
                        <OrderCardHeader order={order} actionButtons={false} {...props}/>
                        <Dimmer active={ [1, id].includes(getLoading) || getLoadingCountries } inverted>
                            <Loader inverted />
                        </Dimmer>
                    </div>
                 : ''}
                <OrderCardHeader order={order} {...props}/>
                <Card.Content className="card-order-content">
                    <Grid className='grid-order-first-second-col'>
                        <Grid.Column className="order-mini-card-header-first-col">
                            <OrderImage/>
                        </Grid.Column>
                        <Grid.Column className="order-mini-card-header-second-col">
                            <Card.Header as="h4" className="order-header-fio-col">
                                {isLoggedIn ? userDataBilling.last_name + ' ' + userDataBilling.first_name : billing.last_name + ' ' + billing.first_name}<br/>
                                {isLoggedIn ? userDataBilling.company : billing.company}<br/>
                                <br/>
                                {isLoggedIn ? userDataBilling.company_job_title : billing.company_job_title}<br/>
                                {isLoggedIn ? userDataBilling.company_scope : billing.company_scope}<br/>
                            </Card.Header>
                        </Grid.Column>
                    </Grid>
                    <div className="order-mini-card-country" size="small"
                         dangerouslySetInnerHTML={{__html: `${orderCountry}, ${orderState}`}}/>
                    <div className="order-mini-card-city" size="small"
                         dangerouslySetInnerHTML={{__html: `${isLoggedIn ? userDataBilling.postcode : billing.postcode} ${isLoggedIn ? userDataBilling.city : billing.city}`}}/>


                    <Segment className="order-mini-card-contacts">
                        <a className="order-mini-card-phone" href={'tel:' + (isLoggedIn ? userDataBilling.phone : billing.phone)}>
                            <Icon name="call" style={{color:'#B10000', width: '13px',height:'13px', transform:'scaleX(-1)'}}/>
                            {isLoggedIn ? userDataBilling.phone : billing.phone}
                        </a>
                        <a className="order-mini-card-mail" href={'mailto:' + (isLoggedIn ? userDataBilling.email : billing.email)}>
                            <Icon name="mail" style={{color:'#B10000', width: '13px',height:'13px'}}/>
                            {isLoggedIn ? userDataBilling.email : billing.email}
                        </a>
                    </Segment>
                    <div className="order-card-action-buttons">
                        {actionButtons
                            ?
                            <div>
                                <Button.Group className="mini-order-action-buttons" fluid>
                                    {needsPayment ?
                                        <>
                                            <Button
                                                className="mini-order-action-button-pay"
                                                onClick={() => setPaymentOpen(true)}
                                                compact
                                            >
                                                {t('payment')}
                                            </Button>
                                        </>
                                        : null}
                                </Button.Group>
                                {modalAddress()}
                                <ModalPayment
                                    open={paymentOpen}
                                    headerIcon="payment"
                                    headerText="Оплата заказа"
                                    handleOpen={() => setPaymentOpen(true)}
                                    handleClose={() => setPaymentOpen(false)}
                                    order={order}
                                />
                            </div>
                            : <Link to={'/orders/' + id}>
                                <Button fluid color="orange">
                                    {t('details')}
                                </Button>
                            </Link>}
                    </div>
                </Card.Content>
            </Card>

        </div>
    );
}