import React, { useEffect, useState } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import * as fabric from 'fabric';
import './style.css';
import { Button } from "semantic-ui-react";

export default function CanvasComponent() {
    const { editor, onReady } = useFabricJSEditor();
    const [popup, setPopup] = useState({ visible: false, left: 0, top: 0, text: '' });
    const [gridSize, setGridSize] = useState(20);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    const text = () => (
        <span className='text-in-box-canvas'>Это красный квадрат</span>
    );



    const createRectangle = (left, top) => {
        const rectangle = new fabric.Rect({
            left,
            top,
            fill: 'rgba(255, 0, 0, 0.5)',
            width: 50,
            height: 50,
            selectable: false,
            evented: true,
        });

        rectangle.on('mouseover', (e) => {
            setPopup({
                visible: true,
                left: e.pointer.x,
                top: e.pointer.y,
                text: text(),
            });
        });

        rectangle.on('mouseout', () => {
            setPopup((prev) => ({ ...prev, visible: false }));
        });

        return rectangle;
    };

    const drawGrid = (canvas) => {
        canvas.getObjects('line').forEach((line) => canvas.remove(line));

        const width = canvas.getWidth();
        const height = canvas.getHeight();

        for (let i = 0; i <= width; i += gridSize) {
            canvas.add(new fabric.Line([i, 0, i, height], {
                stroke: 'lightgrey',
                selectable: false,
                evented: false
            }));
        }

        for (let j = 0; j <= height; j += gridSize) {
            canvas.add(new fabric.Line([0, j, width, j], {
                stroke: 'lightgrey',
                selectable: false,
                evented: false
            }));
        }

        canvas.renderAll();
    };

    useEffect(() => {
        if (editor) {
            fabric.Image.fromURL('https://cms.asiaexpo.space/wp-content/uploads/2024/08/exposition.jpg', (img) => {
                img.set({
                    selectable: false // Сделаем изображение невыбираемым
                });
                editor.canvas.setBackgroundImage(img, editor.canvas.renderAll.bind(editor.canvas));
            });
            const rect1 = createRectangle(300, 50);
            const rect2 = createRectangle(200, 25);
            const rect3 = createRectangle(500, 70);
            editor.canvas.add(rect1);
            editor.canvas.add(rect2);
            editor.canvas.add(rect3);

            //drawGrid(editor.canvas);
            editor.canvas.renderAll();
        }
    }, [editor]);

    useEffect(() => {
        if (editor) {
          //  drawGrid(editor.canvas);
        }
    }, [gridSize, editor]);

    const handleMouseWheel = (e) => {
        e.e.preventDefault();
        if (e.e.deltaY < 0) {
            setGridSize(prev => Math.max(prev - 5, 10));
        } else {
            setGridSize(prev => prev + 5);
        }
    };

    const handleMouseDown = (e) => {
        const canvas = editor.canvas;
        const isShiftPressed = e.e.shiftKey;

        if (isShiftPressed && e.e.button === 0) {
            canvas.on('mouse:move', handleCanvasMove);
        }
    };

    const handleMouseUp = () => {
        const canvas = editor.canvas;
        canvas.off('mouse:move', handleCanvasMove);
    };

    const handleCanvasMove = (e) => {
        const canvas = editor.canvas;
        const { movementX, movementY } = e.e;

        canvas.viewportTransform[4] += movementX; // move along x
        canvas.viewportTransform[5] += movementY; // move along y
        canvas.renderAll();
    };

    useEffect(() => {
        if (editor) {
            editor.canvas.on('mouse:down', handleMouseDown);
            editor.canvas.on('mouse:up', handleMouseUp);
            editor.canvas.on('mouse:wheel', handleMouseWheel);
        }

        return () => {
            if (editor) {
                editor.canvas.off('mouse:down', handleMouseDown);
                editor.canvas.off('mouse:up', handleMouseUp);
                editor.canvas.off('mouse:wheel', handleMouseWheel);
            }
        };
    }, [editor]);

    return (
        <>
            <div className='container-canvas'>
                <FabricJSCanvas className="canvas" onReady={onReady}/>
                <Button.Group className='container-buttons-zoom'>
                    <Button className='button-zoom' onClick={() => setGridSize(prev => prev + 5)}>+</Button>
                    <Button className='button-zoom' onClick={() => setGridSize(prev => Math.max(prev - 5, 10))}>-</Button>
                </Button.Group>
            </div>

            {popup.visible && (
                <div style={{
                    position: 'absolute',
                    left: popup.left + 10,
                    top: popup.top - 20,
                    backgroundColor: 'white',
                    border: '1px solid black',
                    padding: '5px',
                    zIndex: 1000
                }}
                     onMouseEnter={() => setPopup(prev => ({ ...prev, visible: true }))}
                     onMouseLeave={() => setPopup((prev) => ({ ...prev, visible: false }))}>
                    {popup.text}
                </div>
            )}

        </>
    );
}
