import React, {useEffect} from 'react';
import './style_exponent.css';
import InfiniteView from "../../components/InfiniteView";
import {useSelector} from "react-redux";
import {getExponentFilter, getExponents, getExponentSearch, getExponentsFetching, getExponentsHasMore} from "./reducer";
import {
    clearCountryFilter,
    clearSearch,
    clearSegmentFilter,
    EXPONENTS_PAGE_COUNT,
    fetchExponents,
    fetchSegments
} from "./action";


import { ReactComponent as GeoPoint } from '../../icons/geo-point.svg';

import {Flag} from "semantic-ui-react";
import BookmarkComponent from "../../components/Bookmark";
import {Base64} from "../../App";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from "../../icons/sad-icon.png";

import { ReactComponent as Arrow } from '../../icons/arrow-icon.svg';

export default function Exponents(props) {

    const { dispatch, filter, exponents: incomeExponents, routeAction, isPageReloaded, bookmarks, token } = props;

    const exponents = incomeExponents ?? useSelector((state) => getExponents(state.exponents));
    const isFetching = useSelector((state) => getExponentsFetching(state.exponents));
    const hasMore = useSelector((state) => getExponentsHasMore(state.exponents));
    const filterExponents = useSelector((state) => getExponentFilter(state.exponents));
    const searchExponents = useSelector((state) => getExponentSearch(state.exponents));

    const expFilter = filter ??
        {
            countries: filterExponents.country ?? [],
            segments: filterExponents.segment ?? [],
        }

    useEffect(() => {
        if ( routeAction === 'PUSH' || isPageReloaded ) {
            readExponents(1)
             setWindowScrollPosition();
        }
        dispatch(clearSearch());
        // dispatch(clearSegmentFilter())
        // dispatch(clearCountryFilter())
        // readExponents(1)
    }, []);

    useEffect(() => {

            readExponents(1);

    }, [filterExponents, searchExponents]);

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const readExponents = (page)  => {
        const params = {
            page,
            per_page: EXPONENTS_PAGE_COUNT,
            order: 'asc',
            orderby: 'upper(name)',
            event: 'ASIAEXPO 2024',
            lang: languageString,
        };


        if (bookmarks && bookmarks.length > 0) {
            params['bookmarks'] = bookmarks;
        }

        if (expFilter && (expFilter.countries.length > 0 || expFilter.segments.length > 0)) {
            params['filter'] = Base64.encode(JSON.stringify(expFilter));
        }

        if (searchExponents && searchExponents.length >= 3 ) {
            params['search'] = Base64.encode(JSON.stringify(searchExponents));
        }


        dispatch(fetchExponents(token, params));

        if (page === 1) dispatch(fetchSegments({
            hide_empty: true,
            order: 'asc',
            orderby: 'name',
            lang: languageString,
        }));
    }

    const loadMore = () => readExponents(Math.round(exponents.length / EXPONENTS_PAGE_COUNT) + 1);

    const ExponentCard = (props) => {
        const { exponent, navigate } = props;

        const segmentsExponent = exponent.segments ? exponent.segments.map(elem => elem.name).join(', ') : '';

        const locationExponent = exponent.events && exponent.events.active ? exponent.events.active.map(elem => elem.stand_number).join(", ") : '';
       // const archiveExponent = exponent.events && exponent.events.archive ? exponent.events.archive.map(elem => elem.event).join(", ") : '';
       // const activeExponent = exponent.events && exponent.events.active ? exponent.events.active.map(elem => elem.event).join(", ") : '';

        return (
            <>
                    <div className="card_exp">
                        <img src={exponent.logo} className="image_exp"/>
                        <div className="info_exp" onClick={() => navigate(`/exponents/exponents-card/${exponent.id}`)}>
                            <div className="text_exp">
                                <GeoPoint className="geo-point_exp" alt="Geo-point_exp"/>
                                <div className="location_exp">{locationExponent}</div>
                                <Flag name={exponent.country.toLowerCase()} className="country_Russia_exp"/>
                            </div>
                            <div className="name_exp">{languageString === "en" ? exponent.name_en :exponent.name}</div>
                            <div className="description_exp">{languageString === "en" ? exponent.excerpt_en :exponent.excerpt}</div>
                            <Arrow className='more-arrow'/>
                            <span className='text-more-exp' onClick={() => navigate(`/exponents/exponents-card/${exponent.id}`)}>Подробнее</span>

                        </div>
                        <div className="flag_exp">
                            <BookmarkComponent
                                bookmark_using_for="exponents"
                                id={exponent.id}
                                {...props}
                            />
                        </div>
                    </div>
            </>
        )
    }

    const ExponentsList = (props) => {
        const {exponents, title} = props;
        return (
            <div className="container exponents filter-opened">
                {exponents ? exponents.map((element) => (
                    <ExponentCard
                        key={element.id}
                        exponent={element}
                        showLoader
                        {...props}
                    />
                )) : ''}
            </div>
        );
    }

    return (
        <>
            <InfiniteView
                dataLength={exponents.length}
                nextFetch={loadMore}
                hasMore={hasMore}
                dataList={(
                    <ExponentsList
                        exponents={exponents}
                        {...props}
                    />
                )}
                hideEndMessage={true}
                mustAuth={false}
                isLoading={isFetching}
                loaderText={t("loadExponents") + "..."}
                zeroText={(
                    <div className='view-bookmarks'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{t("noExponents")}</span>
                    </div>
                )}
            />
        </>
    );
};



