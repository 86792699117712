import React, {useEffect, useState} from "react";
import {  useSelector } from 'react-redux';
import {fetchForums, FORUMS_PAGE_COUNT} from './actions';
import {getForums, getForumsFetching, getForumsHasMore} from './reducer';
import "./style.css";
import {Button, Divider, Rating} from "semantic-ui-react";
import CalendarIcon from '../../icons/calendar-icon.png';
import { ReactComponent as Arrow } from '../../icons/arrow-icon.svg';

import InfiniteView from "../../components/InfiniteView";
import BookmarkComponent from "../../components/Bookmark";
import {BrowserView, MobileView} from "react-device-detect";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import {clearSearch} from "../Exponents/action";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from "../../icons/sad-icon.png";

export function ForumCard(props) {
    const {forum, showDate, navigate} = props;
    const eventDate =
        forum.event_date.split("-")[2] + "." +
        forum.event_date.split("-")[1] + "." +
        forum.event_date.split("-")[0];

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    return (
        <div className='bookmark-container'>
            <div className="bookmark">
                <div className='bookmark-topbar'>
                    <div className={'box-one'}>
                        <img src={CalendarIcon} className="calendar-icon"/>
                        {showDate ?
                            <span className='header-bookmark-text bookmark-date'>
                                {eventDate}
                            </span> : ''}
                        <span className='header-bookmark-text bookmark-time'>
                            {forum.event_time_from + ' - ' + forum.event_time_to}
                        </span>
                    </div>
                    <div className={'box-two'}>
                        <Rating className='rating-forum-card' maxRating={5} clearablesize="large" disabled defaultRating={forum.rating.avg_value}/>
                        <BookmarkComponent
                            bookmark_using_for="forums"
                            id={forum.id}
                            {...props}
                        />
                    </div>
                </div>
                <div className={'bookmark-inside-all'}>
                    <div className="bookmark-inside">
                        <div id='bookmark-name' className="bookmark-text forum-name">{languageString === "en" ? forum.name_en : forum.name}</div>
                        <div className='dividing-line'></div>
                        <span className="bookmark-text hall-name">{languageString === "en" ? forum.event_placement_name_en : forum.event_placement_name}</span>
                    </div>
                    <div className="bookmark-inside-text">
                        <p className="bookmark-text desc-text" dangerouslySetInnerHTML={{ __html: languageString === "en" ? forum.excerpt_en : forum.excerpt }}>

                        </p>
                    </div>
                </div>
                <div className="viewbutton" onClick={() => navigate(`/business-program/event/${forum.id}`)}>
                    <Arrow className='bookmark-arrow'/>
                    <Button id='bookmark-view'>
                        <span className='bookmark-text-span'>{t("look")}</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default function BusinessProgram(props) {
    const { location, dispatch, parentView, filter, routeAction, isPageReloaded, previousLocations } = props;
    const [activeButton, setActiveButton] = useState(null);
    const forums = useSelector((state) => getForums(state.forums));
    const isFetching = useSelector((state) => getForumsFetching(state.forums));
    const hasMore = useSelector((state) => getForumsHasMore(state.forums));

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    function ForumsList(props) {
        const {forums, title, showDate} = props;
        return (
            <div>
                {forums ? forums.map((element) => (
                    <ForumCard
                        key={element.id}
                        forum={element}
                        showLoader
                        showDate={showDate}
                        {...props}
                    />
                )) : ''}
            </div>
        );
    }

    const handleButtonClick = (index, handleChangePage) => {
        setActiveButton(index)
        handleChangePage()
    }

    const handleChangePage23October = () => {
        readForums(1, '2024-10-23');
    }

    const handleChangePage24October = () => {
        readForums(1, '2024-10-24');
    }

    const handleChangePage25October = () => {
        readForums(1, '2024-10-25');

    }
    useEffect(() => {
        const mustReload = previousLocations.length > 1
            && previousLocations[1].location.pathname.slice(0, 24) !== "/business-program/event/";

        if ( routeAction === 'PUSH' || isPageReloaded || mustReload) {
            window.scrollTo(0, 0);
            handleChangePage23October();
            setActiveButton('2024-10-23');
        } else {
            if (!mustReload) {
                const prevForumId = previousLocations[1].location.pathname.slice(24);
                const prevForum = forums.find((obj) => obj.id === prevForumId);
                setActiveButton(prevForum.event_date);
            }

        }
    }, []);

    const readForums = (page, ondate)  => {
        const params = {
            page,
            per_page: FORUMS_PAGE_COUNT,
            order: 'asc',
            orderby: 'event_date, event_time_from',
            event: 'ASIAEXPO 2024',
            lang: languageString,
        };
        if (!parentView)
            params['event_date'] = ondate;
        if (filter && filter.length > 0)
            params['filter'] = filter;

        dispatch(fetchForums(params));
    }

    const loadMore = () => readForums(Math.round(forums.length / FORUMS_PAGE_COUNT) + 1);

    return (
        <>
            { !parentView ?
                <div className='tab-buttons'>
                    <Button compact id="bookmark-tab-program" className={activeButton === '2024-10-23' ? 'active' : ''}
                            onClick={() => handleButtonClick('2024-10-23', handleChangePage23October)}>{t("oc23bp")}</Button>
                    <Button compact id="bookmark-tab-24-october" className={activeButton === '2024-10-24' ? 'active' : ''}
                            onClick={() => handleButtonClick('2024-10-24', handleChangePage24October)}>{t("oc24bp")}</Button>
                    <Button compact id="bookmark-tab-exhibits" className={activeButton === '2024-10-25' ? 'active' : ''}
                            onClick={() => handleButtonClick('2024-10-25', handleChangePage25October)}>{t("oc25bp")}</Button>
                </div> : '' }
            <InfiniteView
                dataLength={forums.length}
                nextFetch={loadMore}
                hasMore={hasMore}
                dataList={(
                    <ForumsList
                        showDate={!!parentView}
                        forums={forums}
                        {...props}
                    />
                )}
                hideEndMessage={true}
                mustAuth={false}
                isLoading={isFetching}
                loaderText={t("loadForums") + "..."}
                zeroText={(
                    <div className='view-bookmarks'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{t("noForums")}</span>
                    </div>
                )}
            />
        </>
    );
}
