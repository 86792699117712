import React, {useEffect} from 'react';
import {Header, Button, Dimmer, Loader} from 'semantic-ui-react';
import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom';

import './styles.css';
import AddressCard from '../../components/AddressCard';
import CardSummary from '../../components/CardSummary/CardSummary';
import CheckoutSteps, { CHECKOUT_STEP_SHIPPING } from '../../components/CheckoutSteps';
import {getTotalPrice, hasOnlyVirtuals} from '../Cart/reducer';
import {getAddressFields} from "../../components/AddressCard/reducer";
import ShippingCard from "../../components/ShippingCard";
import ConfirmOrderButton from "../../components/ConfirmOrderButton";
import {getPlaceOrderInProgress} from "../Orders/reducer";
import {checkoutOrderPlaced} from "../Orders/actions";
import {getDiscount} from "../../components/CouponCard/reducer";
import {getShippingCost} from "../../components/ShippingCard/reducer";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

export default function CheckoutStepShipping(props) {

    const {dispatch, userLocation, userData, mixpanel, navigate, isLoggedIn} = props;

    const discount = useSelector((state) => getDiscount(state.discount));
    const total_products = useSelector((state) => getTotalPrice(state.cart)) -
        (discount.data && discount.data.discount ? discount.data.discount : 0);

    const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));
    const total_price = useSelector((state) => getTotalPrice(state.cart));
    const addressFields = useSelector((state) => getAddressFields(state.address));
    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));

    useEffect(() => {
        if (mixpanel)
            mixpanel.track('Checkout Address', { ...userLocation, ...userData, ...addressFields });
        window.scrollTo(0, 0);
        dispatch(checkoutOrderPlaced({})); // сбрасываем зависшие сессии по созданию заказа
        if (isLoggedIn)
            navigate('/checkout-step-payment')
    }, [isLoggedIn]);

    const button_text = total_price ? "К выбору способа оплаты" : "Подтвердить заказ";
    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };
    return (
        <div>
            <CheckoutSteps step={CHECKOUT_STEP_SHIPPING} />
            {<Dimmer active={ getLoading === 1 } inverted>
                <Loader inverted> {t('CreatingOrder')}</Loader>
            </Dimmer>}
            <AddressCard {...props}/>
            { !only_virtuals ? <ShippingCard /> : '' }
            <CardSummary total_products={total_products}/>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                { addressFields.hasErrors
                    ? <Button className="checkout-address-buttons-button disabled" color="green" disabled={true}>{button_text}</Button>
                    : (total_price
                        ? <Link to="/checkout-step-payment">
                            <Button className="checkout-address-buttons-button goto-payment-options" color="green">{t('selectPaymentMethod')}</Button>
                        </Link>
                        : <ConfirmOrderButton
                            addClassName="checkout-address-buttons-button"
                            buttonText="Подтвердить заказ"
                            pathToRoute="/checkout-step-confirm"
                            {...props}
                        />)
                }
            </div>
        </div>
    );
}



